import React from 'react';
import { Container } from 'react-bootstrap';


const Delivery = () => {
 
    return (
        <Container style={{maxWidth: "1000px"}}>
            <h1>Доставка</h1>
            <div>
                <h2>
                Отгрузка со склада (г. Ярославль )</h2>
                <p>Данная услуга доступна по адресу: Россия, Ярославская обл., г. Ярославль , Промышленное шоссе 5. При отгрузке запчастей самовывозом вместе с вашим заказом, мы предоставим вам сопроводительные документы. </p>

                <p>Отправка по всей России службами доставки (по вторникам и пятницам)</p>
                <ul>
                <li>«ЖелдорЭкспедиция» </li>
                <li>«Деловые линии» </li>
                <li>«ПЭК» </li>
                <li>«Байкал-Сервис» </li>
                <li>«Кашалот (КИТ)» </li>
                <li>«СДЭК» </li>
                <li>«Энергия» </li>
                <li>«Почта России» (стоимость доставки оплачивается сразу)</li>
                </ul>
                <h2>Доставка крупного заказа</h2>

                <p>При крупном заказе, отправка в Ваш город (по всей России) будет произведена на тентованном грузовом автомобиле. Стоимость доставки рассчитывается индивидуально, исходя из веса груза, города доставки и других факторов.</p>

                <h2>Экспорт запчастей в страны ближнего и дальнего зарубежья</h2>

                <p>Если вы заинтересованы в приобретении запчастей представленных на нашем сайте, то наша компания готова экспортировать их в любую точку ближнего и дальнего зарубежья. Доставка осуществляется наземным транспортом: автомобильным (TIR) или железнодорожным. Условия поставки, сроки и способы отгрузки заказа вы можете уточнить в экспортном отделе продаж.
                </p>
            </div>
        </Container>
    );
};

export default Delivery;