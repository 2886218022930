import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import {check} from "./http/userApi";
import {Container, Spinner} from "react-bootstrap";
import styles from './components/styles/App.module.css'
import { fetchCategories } from './http/deviceApi';
import { compare } from './utils/func';
import ScrollToTop from './components/common/ScrollToTop';

const App = observer(() => {
    const {user} = useContext(Context)
    const [loading, setLoading] = useState(true)
    const [loadingCategory, setLoadingCategory] = useState(true)
    const {device} = useContext(Context)

    useEffect(() => {
        fetchCategories().then(data => device.setCategories(data.sort(compare))).finally( () => setLoadingCategory(false))
        check().then(data => {
            user.setUser(data)
            user.setIsAuth(true)
            user.setIsAdmin(data.role === "ADMIN")
        }).finally(() => setLoading(false))
    }, [])

    if (loading || loadingCategory) {
        return <Spinner animation={"grow"}/>
    }

    return (
        <BrowserRouter>
            <ScrollToTop/>
                <div style={{margin: "0 auto",padding: "0px"}}>
                    <NavBar />
                    <AppRouter />
                    <Footer />
                </div>

        </BrowserRouter>
    );
});

export default App;