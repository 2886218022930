import React from 'react';
import { Container } from 'react-bootstrap';


const UserAgreement = () => {
 
    return (
        <Container style={{maxWidth: "1000px"}}>
            <h1>Пользовательское соглашение</h1>
            <div>
                <p>
                Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между владельцем amk76.ru (далее amk76.ru или Администрация) с одной стороны и пользователем сайта с другой.</p>
                <p>Сайт amk76.ru не является средством массовой информации.</p>

                <p>Используя сайт, Вы соглашаетесь с условиями данного соглашения.</p>
                <p>Если Вы не согласны с условиями данного соглашения, не используйте сайт amk76.ru!</p>

                <h3>Предмет соглашения</h3>
                <p>Администрация предоставляет пользователю право на размещение на сайте следующей информации:</p>

                <h3>Предмет соглашения</h3>
                <p>Администрация предоставляет пользователю право на размещение на сайте следующей информации:</p>
                <ul>
                    <li>Текстовой информации</li>
                    <li>Фотоматериалов</li>
                    <li>Ссылок на материалы, размещенные на других сайтах</li>
                </ul>

                <h3>Права и обязанности сторон</h3>
                <h4>Пользователь имеет право:</h4>
                <ul>
                    <li>осуществлять поиск информации на сайте</li>
                    <li>получать информацию на сайте</li>
                    <li>комментировать контент, выложенный на сайте</li>
                    <li>копировать информацию на другие сайты с разрешения правообладателя</li>
                    <li>использовать информацию сайта в личных некоммерческих целях</li>
                </ul>

                <h4>Администрация имеет право:</h4>
                <ul>
                    <li>по своему усмотрению и необходимости создавать, изменять, отменять правила</li>
                    <li>ограничивать доступ к любой информации на сайте</li>
                    <li>создавать, изменять, удалять информацию</li>
                    <li>удалять учетные записи</li>
                    <li>отказывать в регистрации без объяснения причин</li>
                </ul>

                <h3>Пользователь обязуется:</h3>
                <ul>
                    <li>обеспечить достоверность предоставляемой информации</li>
                    <li>обеспечивать сохранность личных данных от доступа третьих лиц</li>
                    <li>обновлять Персональные данные, предоставленные при регистрации, в случае их изменения</li>
                    <li>не копировать информацию с других источников</li>
                    <li>при копировании информации с других источников, включать в её состав информацию об авторе</li>
                    <li>не нарушать работоспособность сайта</li>
                    <li>не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и тому же лицу</li>
                    <li>не совершать действия, направленные на введение других Пользователей в заблуждение</li>
                    <li>не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам</li>
                    <li>не регистрировать учетную запись от имени или вместо другого лица за исключением случаев, предусмотренных законодательством РФ</li>
                    <li>не размещать материалы рекламного, эротического, порнографического или оскорбительного характера, а также иную информацию, размещение которой запрещено или противоречит нормам действующего законодательства РФ</li>
                    <li>не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами</li>
                </ul>

                <h3>Администрация обязуется:</h3>
                <ul>
                    <li>поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам.</li>
                    <li>предоставить всю доступную информацию о Пользователе уполномоченным на то органам государственной власти в случаях, установленных законом</li>
                </ul>

                <h3>Ответственность сторон</h3>
                <ul>
                    <li>пользователь лично несет полную ответственность за распространяемую им информацию</li>
                    <li>администрация не несет никакой ответственности за достоверность информации, скопированной из других источников</li>
                    <li>администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных услуг</li>
                    <li>администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами</li>
                    <li>в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса</li>
                </ul>

                <p>Условия действия Соглашения</p>
                <p>Данное Соглашение вступает в силу при любом использовании данного сайта.</p>
                <p>Соглашение перестает действовать при появлении его новой версии.</p>
                <p>Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.</p>
                <p>Администрация не оповещает пользователей об изменении в Соглашении.
                                </p>
            </div>
        </Container>
    );
};

export default UserAgreement;