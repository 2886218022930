import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import ListGroup from "react-bootstrap/ListGroup";
import { Button, Image } from 'react-bootstrap';
import menu from "../assets/menu_cat.png"
import dots from "../assets/dots.png"
import styles from "./styles/TypeBar.module.css"
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { CATALOG_ROUTE } from '../utils/consts';

const CatalogBar = (props) => {
    const {device} = useContext(Context)
    const navigate = useNavigate()
    const [size, setSize] = useState(10)
    const [result, setResult] = useState((device.categories.filter(category => category.familyId===0)).filter( (_, index) => index<size))
    
    const handleResize = () => {
        if(props.countType === "noResize"){
            device.setSelectedCategory({id:0})
            navigate(CATALOG_ROUTE)
        } else if(props.countType === "resize") {
            setSize(prev => prev+10)
        }
    }

    useEffect( () => {
        if(props.countType === "resize")
            setResult((device.categories.filter(category => category.familyId===0)).filter( (_, index) => index < size))
    }, [props.countType, size])

    return (
        <div className = { styles.type__bar }>
            <Button 
            variant = "castom"
            onClick = { () => {
                    device.setSelectedCategory({id:0})
                    navigate(CATALOG_ROUTE)}}
            className = { styles.menu__category }><Image className = { styles.category__img } src = { menu } /><span>Все категории</span></Button>

            <ListGroup className = { styles.category__ul}>
                {result.map(category =>
                    <Link
                        variant = 'custom'
                        className = { styles.category__li }
                         active = { category.id === device.selectedCategory.id }
                         onClick = { () => {
                            device.setSelectedCategory(category)
                            device.setPage(1) }}
                        to = { CATALOG_ROUTE + `/` + category.link}
                        key = { category.id }
                    >
                        { category.name }
                    </Link>
                )}
            </ListGroup>
            
            <Button
                variant='castom'
                style = {{display: ((device.categories.filter(category => category.familyId===0)).length === result.length) ? "none" : "flex"}}
                onClick = { () => handleResize()}
                className = { styles.category__more }>
                <Image className = { styles.more__img } src = { dots } />
                <span className = { styles.more_more}>Еще</span>
            </Button>
        </div>
    );
};

export default CatalogBar;