import React from 'react';
import { Container } from 'react-bootstrap';


const PrivacyPolicyAndOffer = () => {
 
    return (
        <Container style={{maxWidth: "1000px"}}>
            <h1>Политика конфиденциальности и оферта</h1>
            <div>
                <h3>Перечень терминов и сокращений</h3>
                <p>Автоматизированная обработка персональных данных - Обработка персональных данных с помощью средств вычислительной техники.</p>
                <p>Блокирование персональных данных - Временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</p>
                <p>Информационная система персональных данных (ИСПДн) - Информационная система персональных данных (ИСПДн)</p>
                <p>Конфиденциальность персональных данных - Обязательное для соблюдения Обществом (его работниками) 
                    и/или иными лицами, получившими доступ к персональным данным, требование не раскрывать третьим лицам 
                    и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено законодательством Российской Федерации.</p>
                <p>Неавтоматизированная обработка персональных данных - Обработка персональных данных, 
                    содержащихся в информационной системе персональных данных либо извлеченных из такой системы, 
                    считается осуществленной без использования средств автоматизации (неавтоматизированной), 
                    если такие действия с персональными данными, как использование, уточнение, распространение (предоставление), 
                    уничтожение персональных данных в отношении каждого из субъектов персональных данных, осуществляются при непосредственном участии человека.</p>
                <p>Обезличивание персональных данных - Действия, в результате которых становится невозможным без 
                    использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.</p>
                <p>Обработка персональных данных - Любое действие (операция) или совокупность действий (операций), 
                    совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, 
                    включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, 
                    использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
                <p>Персональные данные - Любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</p>
                <p>Предоставление персональных данных - Действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</p>
                <p>Раскрытие персональных данных - Умышленное и/или случайное нарушение конфиденциальности персональных 
                    данных лицами (в том числе работниками Общества и иными лицами, действующими по поручению Общества), 
                    получившими доступ к персональным данным и/или обрабатывающими персональные данные субъектов.</p>
                <p>Распространение персональных данных - Действия, направленные на раскрытие персональных данных неопределенному кругу лиц.</p>
                <p>Субъекты персональных данных - Физические лица, чьи персональные данные подлежат обработке в Обществе.</p>
                <p>Трансграничная передача персональных данных - Передача персональных данных на территорию 
                    иностранного государства, иностранному физическому лицу или иностранному юридическому лицу.</p>
                <p>Уничтожение персональных данных - Действия, в результате которых становится невозможным 
                    восстановить содержание персональных данных в информационной системе персональных 
                    данных и (или) в результате которых уничтожаются материальные носители персональных данных.</p>
                    <br></br>
                <h3>1. Общие положения</h3>
                <p>Политика в отношении обработки персональных данных (далее – Политика) является официальным 
                    документом ООО ВПК РЕГИОН и разработана в целях предоставления доступа к сведениям об 
                    обработке и защите персональных данных в ООО «ВПК РЕГИОН » (далее – Общество).</p>
                <p>Настоящая Политика учитывает требования законодательства Российской Федерации в области 
                    обработки и защиты персональных данных и требования внутренних нормативных документов Общества в области обеспечения информационной безопасности.</p>
                <p>Действие Политики распространяется на всю информацию Общества, содержащую персональные данные, 
                    в электронном виде и на материальных носителях, в том числе создаваемую, получаемую, 
                    передаваемую, хранимую и обрабатываемую с использованием автоматизированных и телекоммуникационных систем Общества.</p>
                    <br></br>
                <p>Обработка персональных данных в Обществе осуществляется в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».</p>
                <p>Политика, являющаяся публичным документом, доступна любому пользователю официального сайта Общества.</p>
                <p>Владельцем Политики является подразделение «Информационная безопасность» Общества.</p>
                <br></br>
                <h3>2. Правовые основания обработки персональных данных</h3>
                <p>Обработка персональных данных субъектов осуществляется Обществом в соответствии со следующими нормативными правовыми актами:</p>
                <p>Конституция Российской Федерации;</p>
                <p>Трудовой кодекс Российской Федерации от 30.12.2001 № 197-ФЗ;</p>
                <p>Налоговый кодекс Российской Федерации от 31.07.1998 № 146-ФЗ (часть первая), от 05.08.2000 № 117-ФЗ (часть вторая);</p>
                <p>Налоговый кодекс Российской Федерации от 31.07.1998 № 146-ФЗ (часть первая), от 05.08.2000 № 117-ФЗ (часть вторая);</p>
                <p>Гражданский кодекс Российской Федерации от 30.11.1994 № 51-ФЗ (часть первая), от 26.01.1996 № 14-ФЗ (часть вторая), от 26.11.2001 №146-ФЗ (часть третья);</p>
                <p>Федеральный закон от 15.12.2001 № 167-ФЗ «Об обязательном пенсионном страховании в Российской Федерации» (с изменениями и дополнениями);</p>
                <p>Федеральный закон от 29.11.2010 № 326-ФЗ «Об обязательном медицинском страховании в Российской Федерации» (с изменениями и дополнениями);</p>
                <p>Постановление Правительства Российской Федерации от 27.11.2006 № 719 «Об утверждении Положения о воинском учете» (с изменениями и дополнениями);</p>
                <p>Федеральный закон от 07.08.2001 № 115-ФЗ «О противодействии легализации (отмыванию) доходов, 
                    полученных преступным путем, и финансированию терроризма» (с изменениями и дополнениями);</p>
                <p>Федеральный закон от 30.12.2004 № 214-ФЗ «Об участии в долевом строительстве многоквартирных домов 
                    и иных объектов недвижимости и о внесении изменений в некоторые законодательные акты Российской Федерации» (с изменениями и дополнениями);</p>
                <p>Федеральный закон от 16.07.1998 № 102-ФЗ «Об ипотеке (залоге недвижимости)» (с изменениями и дополнениями);</p>
                <p>Федеральный закон от 21.07.1997 № 122-ФЗ «О государственной регистрации прав на недвижимое имущество и сделок с ним» (с изменениями и дополнениями);</p>
                <p>Федеральный закон от 29.12.2006 № 256-ФЗ «О дополнительных мерах государственной поддержки семей, имеющих детей» (с изменениями и дополнениями);</p>
                <p>Федеральный закон от 20.08.2004 № 117-ФЗ «О накопительно-ипотечной системе жилищного обеспечения военнослужащих» (с изменениями и дополнениями);</p>
                <p>Федеральный закон от 29.07.1998 № 135-ФЗ «Об оценочной деятельности в Российской Федерации» (с изменениями и дополнениями);</p>
                <p>Федеральный закон от 27.07.2010 № 210-ФЗ «Об организации предоставления государственных и муниципальных услуг» (с изменениями и дополнениями);</p>
                <p>Федеральный закон от 11.08.1995 № 135-ФЗ «О благотворительной деятельности и добровольчестве (волонтерстве)» (с изменениями и дополнениями);</p>
                <p>Федеральный закон от 26.10.2002 № 127-ФЗ «О несостоятельности (банкротстве)» (с изменениями и дополнениями);</p>
                <p>Федеральный закон от 27.07.2006 № 152-ФЗ «О персональных данных»;</p>
                <p>Постановление Правительства Российской Федерации от 01.11.2012 № 1119 «Об утверждении 
                    требований к защите персональных данных при обработке в информационных системах персональных данных»;</p>
                <p>Приказ ФСТЭК России от 18.02.2013 № 21 «Об утверждении состава и содержания организационных и технических 
                    мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных»;</p>
                <p>Устав ООО «ВПК РЕГИОН ».</p>
                <br></br>
                <h3>3. Цели и способы обработки персональных данных</h3>
                <p>Обработка персональных данных осуществляется в следующих целях:</p>
                <p>Обеспечения соответствия требованиям Трудового кодекса Российской Федерации, Налогового 
                    кодекса Российской Федерации, Гражданского кодекса Российской Федерации и других нормативно-правовых актов Российской Федерации;</p>
                <p>Принятия решения о трудоустройстве кандидатов в ООО «ВПК РЕГИОН » и ведения кадрового резерва;</p>
                <p>Ведения учета персонала;</p>
                <p>Выполнения обязательств работодателя по трудовому договору;</p>
                <p>Содействия в получении социальных льгот;</p>
                <p>Содействия работникам в обучении и карьерном росте;</p>
                <br></br>
                <p>Обеспечения выполнения обязательств по договору займа / кредитному договору;</p>
                <p>Обеспечения соответствия стандартам процедур выдачи, рефинансирования и сопровождения ипотечных кредитов (займов), утвержденным в ООО «ВПК РЕГИОН »;</p>
                <p>Организации вторичного рынка ипотечных кредитов и займов;</p>
                <p>Приобретения и реализации прав требования по обязательствам, обеспеченным ипотекой;</p>
                <p>Предоставления арендного жилья;</p>
                <p>Отбора поставщиков товаров/работ/услуг для нужд ООО «ВПК РЕГИОН »;</p>
                <p>Обеспечения раскрытия информации в соответствии с требованиями законодательства Российской Федерации в области ценных бумаг;</p>
                <p>Обеспечения договорных обязательств между ООО «ВПК РЕГИОН » и контрагентами (физическими и юридическими лицами);</p>
                <p>Формирования системы рефинансирования долгосрочных ипотечных жилищных кредитов;</p>
                <p>Создания инфраструктуры ипотечного рынка, стимулирования его роста и распространения во всех регионах Российской Федерации;</p>
                <p>Исполнения отдельных функций в соответствии с требованиями федерального законодательства, поручениями Президента Российский Федерации, актами и директивами Правительства Российской Федерации;</p>
                <p>Обработки обращений и запросов граждан;</p>
                <p>Соблюдение внутриобъектового режима;</p>
                <p>Оказания благотворительной помощи.</p>
                <p>Действиями, совершаемыми с персональными данными, являются сбор, запись, 
                    систематизация, накопление, хранение, уточнение (обновление, изменение), 
                    извлечение, использование, передача (предоставление, доступ), обезличивание, блокирование, удаление и уничтожение.</p>
                <p>Обработка персональных данных осуществляется как с использованием средств автоматизации, 
                    так и без использования таковых средств (неавтоматизированная обработка).</p>
                <p>Общество осуществляет обработку персональных данных, относящихся к состоянию здоровья 
                    субъектов персональных данных, но не осуществляет обработку персональных данных, 
                    относящихся к расовой и национальной принадлежности, политическим взглядам, религиозным и 
                    философским убеждениям, интимной жизни и сведениям о судимости. Таким образом, Общество 
                    осуществляет обработку специальных категорий персональных данных.</p>
                <p>Общество с письменного согласия работника осуществляет обработку биометрических 
                    персональных данных (отпечатков пальцев) с целью идентификации работника в информационных 
                    системах Общества и контроля физического доступа (включая учет времени прихода и ухода) в помещения Общества.</p>
                <p>Общество не осуществляет обработку персональных данных в целях продвижения товаров, работ, услуг на рынке.</p>
                <p>Общество не осуществляет трансграничную передачу персональных данных на территории иностранных государств.</p>
                <p>Общество осуществляет сбор и обработку персональных данных с использованием баз данных, расположенных на территории Российской Федерации.</p>
                <p>Обществом запрещается распространение персональных данных. Режим конфиденциальности персональных данных должен 
                    соблюдаться всеми работниками и третьими лицами, которые получили санкционированный доступ и осуществляют обработку персональных данных.</p>
                    <br></br>
                <h3>4. Категории субъектов персональных данных</h3>
                <p>Общество осуществляет обработку персональных данных следующих категорий субъектов персональных данных:</p>
                <p>Текущие и бывшие работники Общества, а также их родственники;</p>
                <p>Кандидаты на замещение вакантных должностей в Обществе;</p>
                <p>Текущие и потенциальные клиенты и контрагенты Общества (физические лица);</p>
                <p>Представители / работники текущих и потенциальных клиентов и контрагентов Общества (юридических лиц);</p>
                <p>Лица, персональные данные которых передаются в Общество в рамках исполнения договоров 
                    с клиентами и контрагентами или в рамках исполнения отдельных функций в соответствии 
                    с требованиями законодательства Российской Федерации, поручениями Президента Российский Федерации, актами и директивами Правительства Российской Федерации;</p>
                <p>Члены Правления, члены Наблюдательного совета, члены комитетов при Наблюдательном совете, члены Ревизионной комиссии;</p>
                <p>Арендаторы и лица, проживающие совместно с арендатором жилья, предоставляемого Обществом;</p>
                <p>Участники форумов, проводимых Обществом;</p>
                <p>Лица, направляющие обращения и запросы в Общество;</p>
                <p>Посетители Общества;</p>
                <br></br>
                <p>Соискатели на получение благотворительной помощи.</p>
                <br></br>
                <h3>5. Принципы и условия обработки персональных данных</h3>
                <p>Обработка персональных данных субъектов персональных данных осуществляется Обществом на основе следующих принципов:</p>
                <p>Законности и справедливости;</p>
                <p>Ограничения обработки персональных данных достижением конкретных, заранее определенных и законных целей, соответствия целей обработки персональных данных целям сбора персональных данных;</p>
                <p>Соответствия объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных;</p>
                <p>Достоверности персональных данных, их достаточности для целей обработки и актуальности по отношению к целям обработки, недопустимости обработки персональных данных, избыточных по отношению к заявленной цели их обработки;</p>
                <p>Принятия необходимых мер (либо обеспечения их принятия) по удалению или уничтожению неполных или неточных персональных данных;</p>
                <p>Недопустимости объединения баз данных, содержащих персональные данные созданных для несовместимых между собой целей обработки персональных данных;</p>
                <p>Уничтожения персональных данных после достижения целей обработки или в случае утраты необходимости в их достижении;</p>
                <p>Личной ответственности работников Общества за сохранность и конфиденциальность персональных данных, а также их носителей;</p>
                <p>Наличия четкой разрешительной системы доступа работников Общества к документам и базам данных, содержащим персональные данные;</p>
                <p>Хранения персональных данных в форме, позволяющей определить субъекта, не дольше, чем этого требуют цели обработки, 
                    если срок хранения персональных данных не установлен законодательством Российской Федерации или договором, стороной которого является субъект персональных данных.</p>
                <p>Обработка персональных данных субъектов осуществляется при следующих условиях:</p>
                <p>Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных;</p>
                <p>Обработка персональных данных необходима для достижения целей, предусмотренных международным договором 
                    Российской Федерации или законом, для осуществления и выполнения возложенных законодательством 
                    Российской Федерации на Общество функций, полномочий и обязанностей;</p>
                <p>Обработка персональных данных осуществляется в связи с участием лица в гражданском, 
                    административном, уголовном судопроизводстве, судопроизводстве в арбитражных судах;</p>
                <p>Обработка персональных данных необходима для исполнения судебного акта, акта другого органа или должностного лица, 
                    подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве;</p>
                <p>Обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или 
                    поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта 
                    персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем;</p>
                <p>Обработка персональных данных осуществляется по поручению контрагента Общества в рамках исполнения соответствующего договора.</p>
                <br></br>
                <h3>6. Особенности обработки обществом персональных данных, осуществляемо без использования средств автоматизации</h3>
                <p>Персональные данные при их обработке, осуществляемой без использования средств автоматизации, 
                    должны обособляться от иной информации, в частности путем фиксации их на отдельных материальных 
                    носителях персональных данных, в специальных разделах или на полях форм (бланков).</p>
                <p>При фиксации персональных данных на материальных носителях не допускается фиксация на одном 
                    материальном носителе персональных данных, цели обработки которых заведомо не совместимы. 
                    Для обработки различных категорий персональных данных, осуществляемой без использования средств автоматизации, 
                    для каждой категории персональных данных должен использоваться отдельный материальный носитель.</p>
                    <br></br>
                <p>Работники Общества и лица, заключившие с Обществом договоры гражданско-правового характера, 
                    перед обработкой персональных данных без использования средств автоматизации должны быть проинформированы 
                    о факте обработки ими персональных данных, обработка которых осуществляется Обществом без использования средств автоматизации.</p>
                <p>При использовании типовых форм документов, характер информации в которых предполагает или допускает 
                    включение в них персональных данных (далее – типовая форма), должны соблюдаться следующие условия:</p>
                <p>Типовая форма или связанные с ней документы (инструкция по ее заполнению, карточки, реестры и журналы) 
                    должны содержать сведения о цели обработки персональных данных, осуществляемой без использования средств автоматизации, 
                    наименование и адрес Общества, фамилию, имя, отчество и адрес субъекта персональных данных, источник получения персональных данных, 
                    сроки обработки персональных данных, перечень действий с персональными данными, которые будут совершаться в процессе их обработки, 
                    общее описание используемых Обществом способов обработки персональных данных;</p>
                <p>Типовая форма должна предусматривать поле, в котором субъект персональных данных может поставить 
                    отметку о своем согласии на обработку персональных данных, осуществляемую без использования 
                    средств автоматизации, – при необходимости получения письменного согласия на обработку персональных данных;</p>
                <p>Типовая форма должна быть составлена таким образом, чтобы каждый из субъектов, 
                    персональные данные которых содержатся в документе, имел возможность ознакомиться 
                    со своими персональными данными, содержащимися в документе, 
                    не нарушая прав и законных интересов иных субъектов персональных данных;</p>
                <p>Типовая форма должна исключать объединение полей, предназначенных для 
                    внесения персональных данных, цели обработки которых заведомо несовместимы.</p>
                <p>При обработке персональных данных без использования средств автоматизации должно 
                    обеспечиваться раздельное хранение персональных данных (материальных носителей персональных данных), обработка которых осуществляется в различных целях.</p>
                <br></br>
                <h3>7. Права и обязанности общества при обработке персональных данных</h3>
                <p>Общество при сборе и дальнейшей Обработке персональных данных вправе:</p>
                <p>Получать от субъекта персональных данных согласие на обработку его персональных данных;</p>
                <p>Устанавливать способы получения согласия субъектов персональных данных, не нарушающие их законные права и интересы, 
                    и в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных»;</p>
                <p>Устанавливать правила обработки персональных данных субъектов и вносить изменения и дополнения в настоящую Политику, 
                    самостоятельно разрабатывать и применять формы документов, необходимых для исполнения настоящей Политики;</p>
                <p>Поручать обработку персональных данных другому лицу с согласия субъекта персональных данных 
                    (если иное не предусмотрено законодательством Российской Федерации) на основании заключаемого 
                    с этим лицом договора с учетом требований части 3 статьи 6 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных»;</p>
                <p>Осуществлять иные права, предусмотренные законодательством Российской Федерации, нормативными правовыми актами и локальными актами Общества.</p>
                <p>Общество при сборе и дальнейшей обработке персональных данных обязано:</p>
                <p>Получать персональные данные субъектов на законных основаниях;</p>
                <p>Разъяснять субъекту персональных данных юридические последствия отказа предоставить его персональные данные в случае, 
                    если предоставление персональных данных является обязательным в соответствии с законодательством Российской Федерации;</p>
                <p>Разъяснять субъекту персональных данных порядок принятия решения на основании исключительно автоматизированной 
                    обработки его персональных данных и возможные юридические последствия такого решения, предоставлять возможность 
                    заявить возражение против такого решения, а также разъяснять порядок защиты субъектом персональных данных своих прав и законных интересов;</p>
                    <br></br>
                <p>Организовать и реализовать право субъекта персональных данных на доступ к своим персональным 
                    данным в сроки, по правилам, с учетом ограничений, установленных в статьях 14 и 18 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных»;</p>
                <p>Взаимодействовать с субъектом персональных данных и уполномоченным органом по защите 
                    прав субъектов персональных данных для обработки запросов и обращений в части получения 
                    сведений об обрабатываемых персональных данных, их актуализации, а также обеспечения законности обработки персональных данных;</p>
                <p>Принимать необходимые правовые, организационные и технические меры или обеспечивать 
                    их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, 
                    уничтожения, изменения, блокирования, копирования, предоставления, распространения 
                    персональных данных, а также от иных неправомерных действий в отношении персональных данных.</p>
                <p>Общество должно обеспечить:</p>
                <p>Предотвращение несанкционированного доступа к персональным данным;</p>
                <p>Предупреждение возможности наступления неблагоприятных последствий в результате нарушения порядка доступа к персональным данным;</p>
                <p>Недопущение воздействия на технические средства обработки персональных данных, в результате которого нарушается их функционирование;</p>
                <p>Возможность незамедлительного восстановления информации, содержащей персональные данные, модифицированной или уничтоженной вследствие несанкционированного доступа к ней;</p>
                <p>Исполнение иных обязанностей, предусмотренных законодательством Российской Федерации и локальными нормативными актами Общества.</p>
                <br></br>
                <h3>8. Права субъектов персональных данных</h3>
                <p>Субъект персональных данных имеет право на получение сведений об обработке Обществом его персональных данных, 
                    указанных в части 7 статьи 14 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», 
                    за исключением случаев, предусмотренных частью 8 статьи 14 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».</p>
                <p>Субъект персональных данных вправе требовать от Общества уточнения его персональных данных, их блокирования или уничтожения в случае, 
                    если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для 
                    заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.</p>
                <p>Субъект персональных данных имеет право на отзыв ранее предоставленного согласия на обработку своих персональных данных.</p>
                <p>Если субъект персональных данных считает, что Общество осуществляет обработку его персональных данных с нарушением 
                    требований Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» или иным образом нарушает его права и свободы, 
                    субъект персональных данных вправе обжаловать действия или бездействие Общества в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке.</p>
                <p>Субъект персональных данных имеет право на защиту своих прав и законных интересов, в том числе на возмещение убытков и (или) компенсацию морального вреда, в судебном порядке.</p>
                <br></br>
                <h3>9. Защита персональных данных</h3>
                <p>Под защитой персональных данных понимается ряд правовых, организационных и технических мер, направленных Обществом на:</p>
                <p>Обеспечение защиты персональных данных от неправомерного доступа, уничтожения, модифицирования, блокирования, 
                    копирования, предоставления, распространения, а также от иных неправомерных действий в отношении таких сведений о субъекте персональных данных;</p>
                <p>Соблюдение конфиденциальности персональных данных;</p>
                <p>Реализацию права на доступ к персональным данным.</p>
                <p>Для защиты персональных данных Общество обеспечивает безопасность и конфиденциальность персональных данных в соответствии 
                    с требованиями законодательства Российской Федерации в области персональных данных, в частности устанавливаются 
                    и соблюдаются следующие меры, в том числе обеспечительные меры согласно установленному уровню защищенности персональных данных:</p>
                <p>Назначение Обществом лица, ответственного за организацию обработки персональных данных, и подразделения, ответственного за обеспечение безопасности информации (включая персональные данные);</p>
                <br></br>
                <p>Издание локальных актов по вопросам обработки и защиты персональных данных, а также локальных актов, 
                    устанавливающих процедуры, направленные на предотвращение и выявление нарушений законодательства 
                    Российской Федерации, устранение последствий таких нарушений;</p>
                <p>Осуществление внутреннего контроля и (или) аудита соответствия порядка обработки персональных данных Обществом 
                    требованиям Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» и принятым в соответствии 
                    с ним нормативным правовым актам, требованиям к защите персональных данных, политике Общества в отношении обработки персональных данных, локальным актам Общества;</p>
                <p>Ознакомление работников Общества, осуществляющих обработку персональных данных, 
                    с правилами и требованиями законодательства Российской Федерации, 
                    с локальными нормативными актами Общества, касающимися обработки персональных данных, 
                    и требованиями по обеспечению безопасности, а также при необходимости – обучение указанных работников;</p>
                <p>Проведение оценки вреда, который может быть причинен субъектам персональных данных в случае нарушения ими 
                    Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», соотношение указанного вреда и принимаемых Обществом мер, 
                    направленных на обеспечение выполнения обязанностей, предусмотренных указанным Федеральным законом;</p>
                <p>Определение угроз безопасности персональных данных при их обработке, формирование на их основе модели угроз и их нейтрализация;</p>
                <p>Применение прошедших в установленном порядке процедуру оценки соответствия средств защиты информации для нейтрализации актуальных угроз безопасности персональных данных;</p>
                <p>Оценка эффективности принимаемых мер по обеспечению безопасности персональных данных до ввода в эксплуатацию информационной системы персональных данных;</p>
                <p>Учет машинных носителей персональных данных;</p>
                <p>Обнаружение фактов несанкционированного доступа к персональным данным и принятие соответствующих мер;</p>
                <p>Восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</p>
                <p>Установление правил доступа к персональным данным, обрабатываемым в информационной системе персональных данных, 
                    а также обеспечение регистрации и учета всех действий, совершаемых с персональными данными в информационной системе персональных данных;</p>
                <p>Контроль за принимаемыми мерами по обеспечению безопасности персональных данных и уровнем защищенности информационных систем персональных данных;</p>
                <p>Организация режима обеспечения безопасности помещений, в которых размещены информационные системы персональных данных, 
                    препятствующего возможности неконтролируемого проникновения или пребывания в этих помещениях лиц, не имеющих права доступа в эти помещения;</p>
                <p>Обеспечение сохранности материальных носителей персональных данных;</p>
                <p>Утверждение руководством Общества документа, определяющего перечень лиц, доступ которым к персональным данным, 
                    обрабатываемым в информационных системах персональных данных, необходим для выполнения ими служебных (трудовых) обязанностей;</p>
                <p>Иные требования, предусмотренные законодательством Российской Федерации в области персональных данных.</p>
                <p>Реализация мер по обеспечению безопасности персональных данных осуществляется с помощью разработанной 
                    системы защиты персональных данных, которая в том числе нейтрализует актуальные угрозы безопасности персональных данных и обеспечивает защиту персональных данных в соответствии с уровнем их защищенности.</p>
                <p>Уровень защищенности персональных данных устанавливается комиссией, назначенной приказом Общества, и утверждается генеральным директором или другим уполномоченным лицом.</p>
                <br></br>
                <h3>10. Предоставление и передача персональных данных</h3>
                <p>При предоставлении Обществом персональных данных третьим лицам должны выполняться следующие условия:</p>
                <p>Передача (предоставление) персональных данных третьей стороне осуществляется на основании договора,
                    условием которого является обеспечение третьей стороной конфиденциальности и безопасности персональных данных при их обработке в соответствии с требованиями законодательства Российской Федерации;</p>
                    <br></br>
                <p>В договоре с третьим лицом определены цели обработки и перечень действий (операций) с персональными данными;</p>
                <p>Наличие письменного согласия субъекта персональных данных на передачу его персональных данных третьей стороне, за исключением случаев, предусмотренных законодательством Российской Федерации.</p>
                <p>При предоставлении Обществом персональных данных третьим лицам должны выполняться следующие условия:</p>
                <br></br>
                <h3>11. Сроки обработки (хранения) персональных данных</h3>
                <p>Порядок хранения персональных данных, обрабатываемых в Обществе, определяется нормативными 
                    документами Общества в соответствии с положениями Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».</p>
                <p>Сроки обработки (хранения) персональных данных определяются в соответствии со сроком действия договора 
                    с субъектом персональных данных, приказом Минкультуры России от 25.08.2010 № 558 «Об утверждении 
                    «Перечня типовых управленческих архивных документов, образующихся в процессе деятельности государственных органов,
                    органов местного самоуправления и организаций, с указанием сроков хранения», сроками исковой давности, 
                    а также иными сроками, установленными законодательством Российской Федерации и нормативными документами Общества.</p>
                <p>Персональные данные, срок обработки (хранения) которых истек, должны быть уничтожены, 
                    если иное не предусмотрено федеральным законом или нормативными документами Общества.</p>
                    <br></br>
                <h3>12. Уточнение, изменение, блокирование и уничтожение персональных данных субъектов</h3>
                <p>В случае выявления неправомерной обработки персональных данных, осуществляемой Обществом или лицом, действующим по поручению Общества, в срок, не превышающий 
                    3 (трех) рабочих дней с даты этого выявления, Общество обязано прекратить неправомерную обработку персональных данных или обеспечить прекращение неправомерной 
                    обработки персональных данных лицом, действующим по поручению Общества. В случае если обеспечить правомерность обработки персональных данных невозможно, 
                    Общество в срок, не превышающий 10 (десяти) рабочих дней с даты выявления неправомерной обработки персональных данных, обязано уничтожить 
                    такие персональные данные или обеспечить их уничтожение. Об устранении допущенных нарушений или об уничтожении персональных данных Общество 
                    обязано уведомить субъекта персональных данных или его представителя, а в случае, если обращение субъекта персональных данных или его 
                    представителя либо запрос уполномоченного органа по защите прав субъектов персональных данных были направлены 
                    уполномоченным органом по защите прав субъектов персональных данных, также указанный орган.</p>
                <p>В случае достижения цели обработки персональных данных Общество обязано прекратить обработку персональных данных или обеспечить ее прекращение 
                    (если обработка персональных данных осуществляется другим лицом, действующим по поручению Общества) и уничтожить персональные 
                    данные или обеспечить их уничтожение (если обработка персональных данных осуществляется другим лицом, действующим по поручению Общества) 
                    в срок, не превышающий 30 (тридцати) дней с даты достижения цели обработки персональных данных, если иное не предусмотрено договором, 
                    стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных, иным соглашением между Обществом и субъектом 
                    персональных данных либо если Общество не вправе осуществлять обработку персональных данных без согласия субъекта персональных данных на основаниях, 
                    предусмотренных законодательством Российской Федерации.</p>
                    <br></br>
                <p>В случае отзыва субъектом персональных данных согласия на обработку его персональных данных Общество обязано 
                    прекратить их обработку или обеспечить прекращение такой обработки (если обработка персональных данных осуществляется 
                    другим лицом, действующим по поручению Общества) и в случае, если сохранение персональных данных более не требуется для 
                    целей обработки персональных данных, уничтожить персональные данные или обеспечить их уничтожение (если обработка персональных 
                    данных осуществляется другим лицом, действующим по поручению Общества) в срок, не превышающий 30 (тридцати) дней с даты поступления 
                    указанного отзыва, если иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является 
                    субъект персональных данных, иным соглашением между Обществом и субъектом персональных данных либо если Общество не вправе осуществлять 
                    обработку персональных данных без согласия субъекта персональных данных на основаниях, предусмотренных законодательством Российской Федерации.</p>
                <p>В случае отсутствия возможности уничтожения персональных данных в течение срока, указанного в пункте
                    10 настоящей Политики, Общество осуществляет блокирование таких персональных данных или обеспечивает их блокирование 
                    (если обработка персональных данных осуществляется другим лицом, действующим по поручению Общества) и обеспечивает уничтожение 
                    персональных данных в срок не более чем 6 (шесть) месяцев, если иной срок не установлен законодательством Российской Федерации.</p>
                    <br></br>
                <h3>13. Порядок обработки обращений субъектов персональных данных</h3>
                <p>Работником, ответственным за взаимодействие с субъектами персональных данных в соответствии 
                    с требованиями законодательства Российской Федерации в области обработки и защиты персональных данных, 
                    является лицо, ответственное за организацию обработки персональных данных.</p>
                <p>Субъект персональных данных может направить обращение как на бумажном носителе, так и в форме электронного документа.</p>
                <p>Первичный учет поступивших обращений от субъектов персональных данных осуществляется в соответствии с правилами внутреннего документооборота Общества.</p>
                <p>Ответ субъекту персональных данных (или его представителю) направляется Обществом вне зависимости от формы обращения субъекта (письменная или электронная) и результатов рассмотрения обращения.</p>
                <p>В случае если обращение направлено субъектом персональных данных (или его представителем) повторно 
                    с целью получения информации об обработке его персональных данных, Общество осуществляет проверку временного периода, 
                    прошедшего с даты последнего обращения субъекта персональных данных (или его представителя). 
                    Если данный временной период составляет менее 30 (тридцати) рабочих дней и по предыдущему обращению 
                    субъекта персональных данных была предоставлена полная информация в соответствии с его обращением, 
                    Общество готовит и направляет письменный ответ субъекту персональных данных (или его представителю) 
                    с отказом в предоставлении запрашиваемых субъектом персональных данных сведений со ссылкой на пункт 6 статьи 14 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».</p>
                <p>Обращения субъектов персональных данных (или их представителей) проверяются на наличие:</p>
                <p>Ф.И.О. заявителя (представителя субъекта персональных данных – если применимо);</p>
                <p>Ф.И.О. субъекта персональных данных;</p>
                <p>Номера основного документа, удостоверяющего личность субъекта персональных данных (и его законного представителя – если применимо), сведений
                    о дате выдачи указанного документа и выдавшем органе, а также документа, подтверждающего право представления интересов субъекта (для законного представителя);</p>
                <p>Сведений, подтверждающих участие субъекта персональных данных в отношениях с Обществом (факт обработки персональных данных Обществом);</p>
                <p>собственноручной подписи субъекта персональных данных (или его представителя) – для письменных обращений.</p>
                <p>В случае необходимости работник, ответственный за организацию обработки персональных данных, запрашивает дополнительную информацию у субъекта персональных данных (или его представителя).</p>
                <br></br>
                <p>Срок предоставления ответа субъекту персональных данных (или его представителю) не превышает 30 (тридцати) дней 
                    с даты получения обращения. Контроль за своевременным предоставлением ответа осуществляется подразделением, ответственным за обеспечение безопасности персональных данных.</p>
                    <br></br>
                <h3>14. Ответственность общества и его работников за нарушение законодательства Российской Федерации, нормативных правовых актов и локальных актов в области персональных данных и их защиты</h3>
                <p>Общество ответственно за обеспечение безопасности персональных данных субъектов персональных данных. В случае поручения (частичного поручения) 
                    обработки персональных данных третьему лицу Общество обязано предусмотреть в договоре обязанность третьего лица соблюдать требования по 
                    конфиденциальности, правила обработки и обеспечивать безопасность полученных персональных данных при их обработке.</p>
                <p>Общество несет ответственность за нарушение законодательства Российской Федерации в области персональных данных, настоящей Политики и
                    других локальных нормативных актов Общества в отношении обработки и защиты персональных данных.</p>
                <p>Работники Общества, виновные в нарушении норм, регулирующих обработку, в том числе сбор (получение), хранение персональных данных, и
                    защиту персональных данных субъектов персональных данных, несут дисциплинарную, административную, уголовную,
                    гражданско-правовую ответственность в соответствии с законодательством Российской Федерации.</p>
                    <br></br>
                <h3>15. Заключительные положения</h3>
                <p>При обеспечении безопасности персональных данных во всех случаях, не описанных в настоящей Политике, Общество руководствуется законодательством Российской Федерации</p>
            </div>
        </Container>
    );
};

export default PrivacyPolicyAndOffer;