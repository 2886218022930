export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const ACCOUNT_ROUTE = '/account'
export const FOLLOW_ROUTE = '/follow'
export const CATALOG_ROUTE = '/catalog'
export const REGISTRATION_ROUTE = '/registration'
export const BASKET_ROUTE = '/basket'
export const DEVICE_ROUTE = '/device'
export const SHOP_ROUTE = '/'
export const RECOVERY_ROUTE = '/recovery'
export const SEARCH_ROUTE = '/search'
export const ABOUT_ROUTE = '/about'
export const CONTACTS_ROUTE = '/contacts'
export const DELIVERY_ROUTE = '/delivery'
export const PPO_ROUTE = '/ppo'
export const PAY_ROUTE = '/pay'
export const USER_AGREEMENT_ROUTE = '/user-agreement'
export const FEEDBACK_ROUTE = '/feedback'