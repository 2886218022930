import React from 'react';
import { Container } from 'react-bootstrap';


const Pay = () => {
 
    return (
        <Container style={{maxWidth: "1000px"}}>
            <h1>Оплата</h1>
            <div>
                <h2>Оплата наличными у нас в офисе</h2>

                <p>При отгрузке запчастей самовывозом оплата наличными в кассу компании. Вместе с Вашим заказом мы предоставим товарный чек. Данная услуга доступна в офисе по адресу: г. Ярославль  , улица Промышленная 5</p>

                <h2>Оплата через Сбербанк онлайн для физлиц</h2>

                <p>Вы можете оплатить свой заказ при помощи банковской карты Сбербанк , Альфа-Банк , Тинькоф , СГБ . 
                    В приложении онлайн в разделе платежи выбираете Оплата по Номеру телефона или номеру карты , который мы Вам пришлем по электронной почте, Вайбер или Ватсап (заполняются реквизиты нашего счета автоматически), вводите сумму и нажимаете оплатить. 
                    Следует отметить, что оплата заказа происходит моментально, что значительно сокращает время его получения, в отличие от других способов оплаты. 
                    Мы сразу же видим поступление средств по заказу, а не через день-два, как при оплате счета. Отгрузка товара начинается сразу же после оплаты, а это означает только одно: вы получите свой заказ в максимально короткие сроки. 
                    С заказанным товаром мы предоставим накладную и кассовый чек.</p>

                <h2>Безналичный расчет для юридических лиц и предпринимателей (с НДС)</h2>

                <p>После оформления заказа, необходимо выслать нам банковские реквизиты Вашей компании по факсу или на e-mail. 
                    Мы выставим вам счет с НДС на указанный Вами e-mail либо факс. 
                    Отгрузка запчастей обычно осуществляется в течении 1-2 дней с момента поступления денежных средств на наш расчетный счет. 
                    С товаром поставляется весь пакет бухгалтерских документов.  </p>

                <h2>Безналичный расчет для нерезидентов России (экспорт)</h2>

                <p>После оформления заказа, необходимо выслать нам банковские реквизиты Вашей компании по факсу или на e-mail. 
                    Мы выставим вам инвойс на указанный Вами e-mail либо факс. 
                    Отгрузка запчастей в зависимости от сложности заказа обычно осуществляется в течении 5-10 дней с момента поступления денежных средств на наш расчетный счет. 
                    Обратите внимание! Условия поставки, сроки и способы отгрузки заказа вы можете уточнить в экспортном отделе продаж.</p>

                <h2>Дополнительная информация: </h2>

                <p>Цены на сайте включают в себя уплату налогов, но не включают стоимость доставки (доставку оплачивает покупатель). Валюта сайта — RUB (российский рубль).
                                </p>
            </div>
        </Container>
    );
};

export default Pay;